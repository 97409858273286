import { getConfigEnv, jsonParse } from '@/utils/helpers';
export const TASQ_STATE_CHANGE_TYPE = 'state change';
export const TASQ_PREDICTION_TYPE = 'Prediction';
export const TASQ_FAILURE_PREDICTION_TYPE = 'failure prediction';
export const TASQ_FAILURE_TYPE = 'Failure event';
export const TASQ_DOWN_TYPE = 'Down';
export const TASQ_LABEL_TYPE = 'Label';
export const TASQ_PROACTIVE_TYPE = 'Proactive';
export const TASQ_PROLONGED_ANOMALY_TYPE = 'Prolonged Anomaly';
export const TASQ_SETPOINT_TYPE = 'Setpoint';
export const TASQ_WELL_TEST_TYPE = 'Well Test Schedule';
export const TASQ_PRODUCING_TYPE = 'Producing';
export const TASQ_COMPLETED_TYPE = 'Completed';
export const TASQ_SCHEDULED_JOB = 'scheduled job';
export const TASQ_PM_TYPE = 'PM';
export const TASQ_GAUGE_TYPE = 'Gauge';
export const TASQ_PLUNGER_CHANGE = 'Plunger Change';
export const TASQ_STANDING_VALVE = 'Standing Valve';
export const TASQ_REGULATORY_TYPE = 'Regulatory';
export const TASQ_SITE_CHECK_TYPE = 'Site check';
export const TASQ_NO_COMMS_TYPE = 'No Comms';
export const TASQ_WORKFLOW_TYPE = 'Workflow';
export const TASQ_MANUALLY_ADDED_TYPE = 'manuallyAdded';
export const TASQ_OFF_TARGET_TYPE = 'Off-Target';
export const TASQ_OFF_TARGET_RT_TYPE = 'Off-Target RT';
export const TASQ_REAL_TIME_TYPE = 'Real-Time';
export const TASQ_OFF_RT_TYPE = 'Real-Time';
export const TASQ_SCHEDULED_JOB_TYPE = 'Scheduled job';
export const TASQ_WAITING_ON_DAYS_LEFT = 'Waiting On Days Left';
export const TASQ_WAITING_ON_OVERDUE = 'Waiting On Overdue';
export const TASQ_REASSIGN_ACTION = 'reassign';
export const TASQ_SNOOZE_ACTION = 'snooze';
export const TASQ_UPDATE_ACTION = 'update';
export const TASQ_REJECT_ACTION = 'reject';
export const TASQ_CREATE_ACTION = 'create';
export const TASQ_LOCK_ACTION = 'lock';
export const TASQ_PREDICTION_LOCK_ACTION = 'prediction-lock-undo';
export const SHOW_ALERT = 'SHOW_ALERT';
export const SHOW_ENDPOINT_RESPONSE_ALERT = 'SHOW_ALERT_BANNER';
export const TASQ_TODO_TYPE = 'TODO_ITEM';

export const MY_TASQS_LIST_ITEM = {
  text: 'My tasqs',
  val: 'SELF',
};
export const MY_TEAM_TASQS_LIST_ITEM = {
  text: 'My team',
  val: 'TEAM',
};
export const ALL_TASQS_LIST_ITEM = {
  text: 'All tasqs',
  val: 'ALL',
};
export const TASQS_LISTS = [
  MY_TASQS_LIST_ITEM,
  MY_TEAM_TASQS_LIST_ITEM,
  ALL_TASQS_LIST_ITEM,
];
export const WAITING_TASQS_LIST_NAME = 'waiting';

export const POST_SHAPEFILE_LAMBDA_NAME = `tasq-mapview-${getConfigEnv("ENV_PREFIX")}-PostShapefileSyncSource`;

// export const GET_ENABLED_ROUTES_LAMBDA_NAME = `tasq-schedule-dev-GetEnabledRoutesSyncSource`;

export const POST_SHAPEFILE_LAMBDA_REGION = 'us-east-2';
export const IDENTITY_POOL_REGION = 'us-east-1';
export const IDENTITY_POOL_ID = getConfigEnv("IDENTITY_POOL_ID");
export const SHAPEFILE_BUCKET_NAME = 'shapefile-zips';
export const SHAPEFILE_BUCKET_REGION = 'us-east-1';
export const SCHEDULE_BUCKET_NAME = 'schedule-zips';
export const SCHEDULE_BUCKET_REGION = 'us-east-1';
// eslint-disable-next-line max-len
export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoidGFzcWluYyIsImEiOiJja25xazB1OXowYnB5Mm9td2M4N2Y0M3dqIn0.1FM09GbksiLN44N-8S4oVg';
export const CHART_TIMES = [
  {
    id: 5,
    val: 500,
    text: 'ALL',
  },
  {
    id: 0,
    val: 365,
    text: '1 Year',
  },
  {
    id: 1,
    val: 180,
    text: '180D',
  },
  {
    id: 2,
    val: 60,
    text: '60D',
  },
  {
    id: 3,
    val: 30,
    text: '30D',
  },
  {
    id: 4,
    val: 7,
    text: '7D',
  },
];
export const CHART_CLICK_EVENT = 'CHART_CLICK_EVENT';
export const WELL_CLICK_EVENT = 'WELL_CLICK_EVENT';
export const HISTORY_SEARCH_UPDATED_EVENT = 'HISTORY_SEARCH_UPDATED_EVENT';
export const SCHEDULE_COMPONENT_COLORS = ["#51cea8", "#e9a284", "#516ae9", "#878891", "#b05ffc", "#fcc55f", "#ff9d96"]
export const SCHEDULE_DATE_TITLE_HEIGHT = 35
export const SCHEDULE_CHART_DAY_WIDTH = 85
export const SCHEDULE_ROW_HEIGHT = 80
export const SCHEDULE_ROW_STACKED_HEIGHT = 40
export const SCHEDULE_PIXELS_TO_CURRENT_DAY = 416
export const SCHEDULE_START_DATE_OFFSET_HOURS = 96
export const TASQ_WAITING_ON_LIST = [
  "Waiting on AL Optimization",
"Waiting on Downhole Flush",
"Waiting on GL Restoration",
"Waiting on Maintenance/Parts",
"Waiting on Pad Optimization",
"Waiting on Pressure Build",
"Waiting on De-complete",
"Waiting on Regulatory",
"Waiting on Repairs",
"Waiting on Return From Frac Hit",
"Waiting on Rig Work/Workover",
"Waiting on SI for Offset Frac",
"Waiting on Slickline",
"Waiting on Surface Flush",
"Waiting on Swabbing",
"Waiting on Third Party Vendor",
"Waiting on Weather"
]
export const PREDICTION_TYPE_ANOMALY = 'anomaly'
export const PREDICTION_TYPE_PROLONGED_ANOMALY = 'prolonged anomaly'
export const PREDICTION_TYPE_STATE_CHANGE = 'state change'
export const PREDICTION_TYPE_TARGET = 'off-target'
export const PREDICTION_TYPE_TARGET_RT = 'off-target rt'
export const PREDICTION_TYPE_SET_POINT = 'setpoint'
export const VERTICAL_LIST_SORT_DETAULT_VALUE = 'deferment-desc'
export const VERTICAL_LIST_SORT_PRIORITY_DESC = 'priority-desc'
export const VERTICAL_LIST_DEFAULT_LEVEL = 'Well'


export const TEST_WELLS = [
  "ROWDY YATES 596-585 2XH",
  "WILLIAM MUNNY 4XH",
  "TRUE GRIT 4H",
  "MCMASTERS 1XH",
  "TRUE GRIT 1H",
  "MISS KITTY 669-704 4XH",
  "MISS KITTY 669-704 2XH",
  "STIFF SADDLE 1XH",
  "RUSTY CRANE 1XH",
  "LAZY HORSE 3XH",
  "HOODOO BROWN 3H",
  "STIFF SADDLE 3XH",
  "BRUSHY BILL 1XH",
  "STAGECOACH 2H",
  "FESTUS 3XH",
  "DOUBLE DOWN 1XH",
  "LAZY HORSE 5XH",
  "CATTLE DRIVE 665 4H",
  "DOUBLE DOWN 602-643-2X",
  "RUSTY CRANE 3H",
  "MISS KITTY 669-704 1XH",
  "LAZY HORSE 2XH",
  "BRUSHY BILL 2XH",
  "BRUSHY BILL 25XH",
  "SHENANDOAH 1H",
  "SHIPROCK 3H",
  "BEATEN PATH 25XH",
  "SUNDANCE 1H",
  "DESPERADO 3H",
  "BEATEN PATH 4X",
  "J TREEHORN 21-16 STATE COM 4H",
  "BEATEN PATH 3X - FDEC",
  "BEATEN PATH 1X",
  "ANNAPURNA 20-1H - FDEC",
  "DESPERADO 538 1H",
  "CLEVELAND 4H - FDEC",
  "CLEVELAND A 601 3H",
  "JEREMIAH JOHNSON 8XH",
  "JOSEY WALES 5XH",
  "BRUSHY BILL 4XH",
  "MADCAP 1XH",
  "BRUSHY BILL 5XH",
  "FESTUS 33XH",
  "SUNDANCE 642 8H",
  "HOSS 605 8H",
  "MADCAP 731-706 4XH",
  "CLEVELAND A 601 1H ARCHIVE",
  "FESTUS 36XH",
  "JOHNNY TYLER 577 3H",
  "STAGECOACH 4H",
  "LAZY HORSE 581 1H",
  "WESTERN SKIES 2XH",
  "MISS KITTY 25XH",
  "DOUBLE DOWN 602-643 4XH",
  "HOODOO BROWN 4H",
  "DESPERADO 5H",
  "SHIPROCK 4H",
  "FESTUS 4XH",
  "DOUBLE DOWN 602-643 3XH",
  "LAZY HORSE 581 4XH",
  "DESPERADO 538 4H",
  "POLARIS 9B 1H - FDEC",
  "BEATEN PATH 2X",
  "PIPER 2 STATE 1-FDEC",
  "DESPERADO 2H",
  "SLIM HONEYCUTT 4H-FDEC",
  "BUTTON MESA SAN ANDRES UNIT 1H",
  "CLEVELAND A 601 2H",
  "WESTERN SKIES 1XH",
  "CLEVELAND A 601 1H-FDEC",
]
